import { GAEventAction, GAEventCategory, GAEventLabel, GAEvent } from "./ga";

export const INVITE_STATUS = {
  SENT: "SENT",
  ACCEPTED: "ACCEPTED",
};

export const INVITE_TYPE = {
  // use website until token is not expired
  TOKEN: "TOKEN",
  // registration required
  USER: "USER",
};

export enum DELIVERY_METHODS {
  EMAIL = "EMAIL",
  TELEGRAM = "TELEGRAM",
  // SIGNAL: 'SIGNAL',
}

export enum TELEGRAM_STATUS {
  PENDING = "PENDING",
  ACTIVATED = "ACTIVATED",
}

export enum ALERT_OBJECT_TYPES {
  ASSET = "asset",
  PAIR = "pair",
}

// TODO: add bigtrade/liquidity change etc
export enum ALERT_TYPES {
  PRICE_CHANGE = "price_change",
  VOLUME_CHANGE = "volume_change",
}

export enum RULE_OBJECT_TYPE {
  ASSET = "asset_object_rule",
  TICKER = "ticker_object_rule",
  CONTRACT = "contract_object_rule",
  EXCHANGE = "exchange_object_rule",
}

export enum RULE_METRIC {
  PRICE = "asset_price_change",
  VOLUME = "asset_volume_change",
}

export enum RULE_CONDITION {
  CHANGE = "asset_price_change_percentage",
  TARGET = "asset_price_target_price",
}

export const DEFAULT_INTERVALS = {
  DAY: {
    title: "24h",
    slug: "1d",
    value: "DAY",
  },
  WEEK: {
    title: "7d",
    slug: "1w",
    value: "WEEK",
  },
  MONTH: {
    title: "30d",
    slug: "1m",
    value: "MONTH",
  },
};

export const DEFAULT_INTERVALS_ANOTHER_FORMAT = {
  DAY: {
    title: "Day",
    slug: "daily",
    value: "DAY",
  },
  WEEK: {
    title: "Week",
    slug: "weekly",
    value: "WEEK",
  },
  MONTH: {
    title: "Month",
    slug: "monthly",
    value: "MONTH",
  },
};

export const DEFAULT_INTERVALS_ARRAY = Array.from(
  Object.entries(DEFAULT_INTERVALS),
).map(([k, v]) => {
  return {
    value: k,
    ...v,
  };
});

export const DEFAULT_INTERVALS_ANOTHER_FORMAT_ARRAY = Array.from(
  Object.entries(DEFAULT_INTERVALS_ANOTHER_FORMAT),
).map(([k, v]) => {
  return {
    value: k,
    ...v,
  };
});

export const DEFAULT_CURRENCIES = {
  USD: {
    title: "USD",
    slug: "usd",
  },
  BTC: {
    title: "BTC",
    slug: "btc",
  },
  ETH: {
    title: "ETH",
    slug: "eth",
  },
};

export interface AlertDelivery {
  method: DELIVERY_METHODS;
  email?: string;
  tg?: {
    username: string;
    activationStatus: TELEGRAM_STATUS;
    mute: boolean;
  };
  confirmationToken?: string;
}

export const DEFAULT_CURRENCIES_ARRAY = Array.from(
  Object.entries(DEFAULT_CURRENCIES),
).map(([k, v]) => {
  return {
    value: k,
    ...v,
  };
});

export interface NavigationItemBase {
  name: string;
  Icon?: (NavigationIconType) => JSX.Element;
  gaEvent?: GAEvent;
}

export interface NavigationItem extends NavigationItemBase {
  path: string;
  auth?: boolean;
}

export interface ParentNavigationItem extends NavigationItemBase {
  items: NavigationItem[];
  path?: string;
}

type NavigationIconType = {
  themeType: string;
  selected: boolean;
  base: string;
};

const NavigationIcon = ({ themeType, base, selected }: NavigationIconType) => {
  let url = `/${base}`;

  if (themeType === "dark") {
    url += "-dark";
  }

  if (selected === true) {
    url += "-selected";
  }

  url += ".svg";

  return <img src={url} />;
};

export const NAVIGATION: Array<NavigationItem | ParentNavigationItem> = [
  {
    name: "Terminal",
    Icon: ({ themeType, selected }) => (
      <NavigationIcon
        base="terminal-icon"
        themeType={themeType}
        selected={selected}
      />
    ),
    path: process.env.NEXT_PUBLIC_TERMINAL_URL,
    gaEvent: {
      action: GAEventAction.Terminal,
      category: GAEventCategory.Header,
      label: GAEventLabel.Terminal,
    },
  },
  {
    name: "Markets",
    Icon: ({ themeType, selected }) => (
      <NavigationIcon
        base="markets-icon"
        themeType={themeType}
        selected={selected}
      />
    ),
    items: [
      {
        name: "Assets",
        path: "/assets",
        gaEvent: {
          action: GAEventAction.Markets,
          category: GAEventCategory.Header,
          label: "Assets",
        },
      },
      {
        name: "Exchanges",
        path: "/exchanges",
        gaEvent: {
          action: GAEventAction.Markets,
          category: GAEventCategory.Header,
          label: "Exchanges",
        },
      },
      // {
      //   name: "Dexes",
      //   path: "/dexes",
      //   gaEvent: {
      //     action: GAEventAction.Markets,
      //     category: GAEventCategory.Header,
      //     label: "Dexes",
      //   },
      // },
      {
        name: "DeFi",
        path: "/defi",
        gaEvent: {
          action: GAEventAction.Markets,
          category: GAEventCategory.Header,
          label: "DeFi",
        },
      },
      {
        name: "ICOs & IEOs",
        path: "/icos-ieos",
        gaEvent: {
          action: GAEventAction.Markets,
          category: GAEventCategory.Header,
          label: "ICOs & IEOs",
        },
      },
      {
        name: "Liquidity",
        path: "/liquidity",
        gaEvent: {
          action: GAEventAction.Markets,
          category: GAEventCategory.Header,
          label: "Liquidity",
        },
      },
      {
        name: "P2P",
        path: "/p2p-transactions",
        gaEvent: {
          action: GAEventAction.Markets,
          category: GAEventCategory.Header,
          label: "P2P",
        },
      },
    ],
  },
  {
    name: "Labs",
    // path: "/labs",
    Icon: ({ themeType, selected }) => (
      <NavigationIcon
        base="labs-icon"
        themeType={themeType}
        selected={selected}
      />
    ),
    items: [
      {
        name: "Crypto Lab",
        path: "/labs/labs-comparison",
        gaEvent: {
          action: GAEventAction.Labs,
          category: GAEventCategory.Header,
          label: "Crypto Lab",
        },
      },
      {
        name: "Trade Simulator",
        path: "/labs/simulator",
        gaEvent: {
          action: GAEventAction.Labs,
          category: GAEventCategory.Header,
          label: "Trade Simulator",
        },
      },
      {
        name: "Correlation",
        path: "/labs/correlation",
        gaEvent: {
          action: GAEventAction.Labs,
          category: GAEventCategory.Header,
          label: "Correlation",
        },
      },
      // {
      //   name: "Arbitrage Arena",
      //   path: "/labs/arbitrage-arena",
      //   gaEvent: {
      //     action: GAEventAction.Labs,
      //     category: GAEventCategory.Header,
      //     label: "Arbitrage Arena",
      //   },
      // },
      {
        name: "Dominance",
        path: "/labs/dominance",
        gaEvent: {
          action: GAEventAction.Labs,
          category: GAEventCategory.Header,
          label: "Dominance",
        },
      },
    ],
  },
  {
    name: "Portfolio",
    Icon: ({ themeType, selected }) => (
      <NavigationIcon
        base="shopping-icon"
        themeType={themeType}
        selected={selected}
      />
    ),
    path: "/portfolio",
    auth: true,
    gaEvent: {
      action: GAEventAction.Portfolio,
      category: GAEventCategory.Header,
      label: "Portfolio",
    },
  },
  {
    name: "News",
    Icon: ({ themeType, selected }) => (
      <NavigationIcon
        base="globe-icon"
        themeType={themeType}
        selected={selected}
      />
    ),
    path: "/news",
    gaEvent: {
      action: GAEventAction.News,
      category: GAEventCategory.Header,
      label: "News",
    },
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
    gaEvent: {
      action: GAEventAction.PrivacyPolicy,
      category: GAEventCategory.Footer,
      label: GAEventLabel.PrivacyPolicy,
    },
  },
  {
    name: "Terms Of Service",
    path: "/terms-of-service",
    gaEvent: {
      action: GAEventAction.TermsOfService,
      category: GAEventCategory.Footer,
      label: GAEventLabel.TermsOfService,
    },
  },
  {
    name: "Login",
    path: "/login",
    gaEvent: {
      action: GAEventAction.Login,
      category: GAEventCategory.Footer,
      label: GAEventLabel.Login,
    },
  },
];

export const isParentNavigationItem = (
  item: NavigationItem | ParentNavigationItem,
) => {
  return "items" in item;
};

export const PAGE_SIZE = parseInt(process.env.OBJECTS_PER_PAGE) || 20;
export const PREVIEW_PAGE_SIZE = 20;
export const PREVIEW_PAGE_SIZE_FOR_ASSETS_PAGE = 20;

export interface LabItemType {
  Icon: keyof JSX.IntrinsicElements;
  title: string;
  subTitle: string;
  path: string;
}
