import { useMemo, useRef, useEffect } from "react";
import { format as d3Format } from "d3-format";
import { currencify } from "@lib/utils/formatting";
import {
  useTheme,
  BoxProps,
  TypographyProps,
  useMediaQuery,
  Breakpoint,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import NumberDynamic from "@components/NumberDynamic";
import { keyframes } from "tss-react";

const useStyles = makeStyles((theme) => ({
  animationValueUp: {
    animation: `${keyframes`
    0% {
      color: ${theme.palette.success.main};
    }
    99% {
      color: ${theme.palette.success.main};
    }
    100% {
      color: ${theme.palette.text.primary};
    }
    `} 1s `,
  },
  animationValueDown: {
    animation: `${keyframes`
    0% {
      color: ${theme.palette.error.main};
    }
    99% {
      color: ${theme.palette.error.main};
    }
    100% {
      color: ${theme.palette.text.primary};
    }
    `} 1s `,
  },

  "@keyframes valueUp": {
    "0%": {
      color: theme.palette.success.main,
    },
    "99%": {
      color: theme.palette.success.main,
    },
    "100%": {
      color: theme.palette.text.primary,
    },
  },
  "@keyframes valueDown": {
    "0%": {
      color: theme.palette.error.main,
    },
    "99%": {
      color: theme.palette.error.main,
    },
    "100%": {
      color: theme.palette.text.primary,
    },
  },
}));

export enum Formats {
  percent = "percent",
  bigNumber = "bigNumber",
  price = "price",
  smallNumber = "smallNumber",
}

const formatSpecifier = {
  [Formats.percent]: {
    normal: ",.2%",
    shrink: ",.2%",
  },
  [Formats.bigNumber]: {
    normal: ",d",
    shrink: ".2s",
  },
  [Formats.price]: {
    normal: ",.4~f",
    shrink: ",d",
  },
  [Formats.smallNumber]: {
    normal: ",.8~f",
    shrink: ",.5~f",
  },
};

export const isBigNumber = (n: number | string, limit = 1e5) =>
  typeof n === "string" ? parseFloat(n) >= limit : n >= limit;

interface PropsBase {
  // number: mark up/down dynamic(like in original NumberDynamic)
  // undefined: disable dynamic
  // "auto": mark up/down based on provided value
  // TODO: remove auto?
  dynamic?: number | "auto";
  hideDynamicArrow?: boolean;
  dynamicBoxProps?: BoxProps;
  typographyProps?: TypographyProps;
  prefix?: string;
  suffix?: string;
  value: string | number;
  // USD | BTC | ETH
  currency?: string;
  upChangeAnimationClass?: string;
  downChangeAnimationClass?: string;
  changeAnimationEnabled?: boolean;
  forceShortFormat?: boolean;
  className?: string;
  shouldShrinkFormat?: Breakpoint;
  shrinkForMobile?: boolean;
}

export interface DefaultFormatProps extends PropsBase {
  // boolean: shrink or not to shrink(using default shrink format)
  // string: custom shrink format
  shrink?: boolean | string;
  format: Formats;
}

export interface CustomFormatProps extends PropsBase {
  // format
  format: string;
  // custom shrink format
  shrink?: string;
}

export default function Number({
  value,
  shrink,
  dynamic,
  format,
  currency,
  hideDynamicArrow,
  dynamicBoxProps,
  typographyProps,
  prefix,
  suffix,
  upChangeAnimationClass,
  downChangeAnimationClass,
  changeAnimationEnabled = false,
  forceShortFormat,
  className,
  shouldShrinkFormat = "md",
  shrinkForMobile = true,
}: CustomFormatProps | DefaultFormatProps) {
  const classes = useStyles();
  const prevValue = useRef(null);
  const elem = useRef(null);
  const theme = useTheme();
  const shouldShrink: boolean = useMediaQuery(
    theme.breakpoints.down(shouldShrinkFormat),
  );

  if (value === "") {
    value = null;
  }

  if (typeof upChangeAnimationClass === "undefined") {
    upChangeAnimationClass = classes.animationValueUp;
  }

  if (typeof downChangeAnimationClass === "undefined") {
    downChangeAnimationClass = classes.animationValueDown;
  }

  let formattedValue;

  const pValue = prevValue.current;

  useEffect(() => {
    if (typeof elem.current === "undefined") {
      return;
    }

    if (value === null || pValue === null) {
      return;
    }

    if (changeAnimationEnabled === false) {
      return;
    }

    const changeClass =
      parseFloat(pValue) > parseFloat(value as string)
        ? downChangeAnimationClass
        : upChangeAnimationClass;

    elem.current.classList.add(changeClass);
  }, [value]);

  if (format in Formats === true) {
    if (typeof shrink === "undefined") {
      // shrink with default format by default
      shrink = true;
    }

    if (format === Formats.percent) {
      if (typeof value === "string") {
        value = parseFloat(value) / 100;
      } else {
        value = value / 100;
      }
    }

    // one of default formats
    if (shrink === false || shouldShrink === false) {
      formattedValue = d3Format(formatSpecifier[format].normal)(value);
    } else {
      formattedValue = d3Format(
        shrink === true ? formatSpecifier[format].shrink : shrink,
      )(value);
    }
  } else {
    // custom format
    if (typeof shrink === "undefined" || shouldShrink === false) {
      formattedValue = d3Format(format)(value);
    } else {
      formattedValue = d3Format(shrink)(value);
    }
  }

  // Formats big numbers on mobile by default
  if (
    isBigNumber(value) &&
    (shouldShrink || forceShortFormat) &&
    typeof shrink !== "string" &&
    shrinkForMobile
  ) {
    formattedValue = d3Format(formatSpecifier.bigNumber.shrink)(value);
  }

  if (format === Formats.price && isBigNumber(value, 1000)) {
    formattedValue = d3Format(formatSpecifier.price.shrink)(value);
  }

  if (format === Formats.percent && isBigNumber(value, 1000)) {
    formattedValue = d3Format(formatSpecifier.percent.shrink)(value);
  }

  // Replacing gigabytes by billions
  if (/.\dG$/.test(formattedValue)) {
    formattedValue = formattedValue.replace(/G/, "B");
  }

  if (typeof currency !== "undefined") {
    formattedValue = currencify(formattedValue, currency);
  }

  const renderedFormattedValue = useMemo(() => {
    if (!prefix && !suffix) return formattedValue;
    let value = formattedValue;
    if (suffix) {
      value += suffix;
    }
    if (prefix) {
      value = prefix + value;
    }
    return value;
  }, [formattedValue, prefix, suffix]);

  prevValue.current = value;

  if (typeof dynamic !== "undefined") {
    let dynamicTrend;

    if (dynamic === "auto") {
      dynamicTrend = !value.toString().startsWith("-") ? 1 : -1;
    } else {
      dynamicTrend = dynamic;
    }

    // needed?
    if (
      typeof className !== "undefined" &&
      typeof typographyProps !== "undefined"
    ) {
      typographyProps.className = className;
    }

    return (
      <NumberDynamic
        boxProps={dynamicBoxProps}
        typographyProps={typographyProps}
        hideArrow={hideDynamicArrow}
        dynamic={dynamicTrend}>
        {renderedFormattedValue}
      </NumberDynamic>
    );
  } else {
    if (changeAnimationEnabled === true) {
      return (
        <span
          className={className}
          ref={elem}
          onAnimationEnd={() => {
            if (typeof elem.current !== "undefined") {
              const element = elem.current as HTMLElement;
              element.classList.remove(downChangeAnimationClass);
              element.classList.remove(upChangeAnimationClass);
            }
          }}>
          {renderedFormattedValue}
        </span>
      );
    } else {
      return <span className={className}>{renderedFormattedValue}</span>;
    }
  }
}
